var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "loginfrm ftco-section" },
    [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row justify-content-center" }, [
          _c("div", { staticClass: "col-md-12 col-lg-10" }, [
            _c("div", { staticClass: "wrap d-md-flex" }, [
              _vm._m(0),
              _c(
                "div",
                { staticClass: "login-wrap p-4 p-lg-5" },
                [
                  _c("div", { staticClass: "d-flex" }, [
                    _c("div", { staticClass: "w-100" }, [
                      _c("h3", { staticClass: "mb-4" }, [
                        _vm._v(_vm._s(_vm.$language("Log In"))),
                      ]),
                    ]),
                    _c("div", { staticClass: "w-100" }),
                  ]),
                  _c(
                    "q-form",
                    {
                      staticClass: "signin-form",
                      on: { submit: _vm.onSubmit },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "form-group mb-3" },
                        [
                          _c("q-input", {
                            ref: "userIdInput",
                            staticClass: "loginText",
                            attrs: {
                              rounded: "",
                              outlined: "",
                              color: "login-main-color",
                              filled: "",
                              placeholder: "ID",
                              name: "userId",
                              required: "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "prepend",
                                fn: function () {
                                  return [
                                    _c("q-icon", { attrs: { name: "person" } }),
                                  ]
                                },
                                proxy: true,
                              },
                              {
                                key: "append",
                                fn: function () {
                                  return [
                                    _c("q-icon", {
                                      staticClass: "cursor-pointer",
                                      attrs: { name: "close" },
                                      on: {
                                        click: function ($event) {
                                          _vm.loginForm.userId = ""
                                        },
                                      },
                                    }),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                            model: {
                              value: _vm.loginForm.userId,
                              callback: function ($$v) {
                                _vm.$set(_vm.loginForm, "userId", $$v)
                              },
                              expression: "loginForm.userId",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "form-group mb-3" },
                        [
                          _c("q-input", {
                            staticClass: "loginText",
                            attrs: {
                              type: _vm.isPwd ? "password" : "text",
                              rounded: "",
                              outlined: "",
                              color: "login-main-color",
                              filled: "",
                              placeholder: "PASSWORD",
                              name: "password",
                              required: "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "prepend",
                                fn: function () {
                                  return [
                                    _c("q-icon", {
                                      attrs: { name: "password" },
                                    }),
                                  ]
                                },
                                proxy: true,
                              },
                              {
                                key: "append",
                                fn: function () {
                                  return [
                                    _c("q-icon", {
                                      staticClass: "cursor-pointer",
                                      attrs: {
                                        name: _vm.isPwd
                                          ? "visibility_off"
                                          : "visibility",
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.isPwd = !_vm.isPwd
                                        },
                                      },
                                    }),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                            model: {
                              value: _vm.loginForm.password,
                              callback: function ($$v) {
                                _vm.$set(_vm.loginForm, "password", $$v)
                              },
                              expression: "loginForm.password",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "q-btn",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                loading: _vm.loading,
                                rounded: "",
                                size: "lg",
                                icon: "lock_open",
                                color: "login-main-color",
                                type: "submit",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "loading",
                                  fn: function () {
                                    return [
                                      _c("q-spinner-gears", {
                                        staticClass: "on-left",
                                      }),
                                      _vm._v(" Sign In... "),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                            },
                            [_vm._v(" Sign In ")]
                          ),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "form-group d-md-flex" }, [
                        _c("div", { staticClass: "w-50 text-left" }, [
                          _c(
                            "label",
                            {
                              staticClass:
                                "checkbox-wrap checkbox-primary mb-0",
                            },
                            [
                              _vm._v("Remember User ID "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.rememberMe,
                                    expression: "rememberMe",
                                  },
                                ],
                                attrs: { type: "checkbox" },
                                domProps: {
                                  checked: Array.isArray(_vm.rememberMe)
                                    ? _vm._i(_vm.rememberMe, null) > -1
                                    : _vm.rememberMe,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a = _vm.rememberMe,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.rememberMe = $$a.concat([$$v]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.rememberMe = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.rememberMe = $$c
                                    }
                                  },
                                },
                              }),
                            ]
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "w-50 text-right" },
                          [
                            _c(
                              "q-btn",
                              {
                                attrs: {
                                  flat: "",
                                  dense: "",
                                  label: _vm.selectedCountry,
                                  icon: "language",
                                },
                              },
                              [
                                _c(
                                  "q-menu",
                                  {
                                    attrs: {
                                      anchor: "bottom left",
                                      self: "top middle",
                                      "auto-close": "",
                                      "transition-show": "jump-down",
                                      "transition-hide": "jump-up",
                                    },
                                  },
                                  [
                                    _c(
                                      "q-list",
                                      { staticClass: "topMenuShortLanguage" },
                                      _vm._l(_vm.LangItems, function (tag) {
                                        return _c(
                                          "q-item",
                                          {
                                            directives: [
                                              {
                                                name: "ripple",
                                                rawName: "v-ripple",
                                              },
                                            ],
                                            key: tag.code,
                                            ref: "tag",
                                            refInFor: true,
                                            class: tag.class,
                                            staticStyle: {
                                              "padding-left": "5px !important",
                                            },
                                            attrs: {
                                              clickable: "",
                                              name: tag.codeName,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.setDefaultLanguage(
                                                  tag
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "q-item-section",
                                              { attrs: { avatar: "" } },
                                              [
                                                _c("country-flag", {
                                                  staticClass: "lanauageFlag",
                                                  attrs: {
                                                    country: tag.country,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "q-item-section",
                                              [
                                                _c(
                                                  "q-item-label",
                                                  { attrs: { lines: "1" } },
                                                  [_vm._v(_vm._s(tag.codeName))]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "text-wrap p-4 p-lg-5 text-center d-flex align-items-center order-md-last",
      },
      [
        _c("div", { staticClass: "text w-100 anivertilogo" }, [
          _c("h2", [_vm._v("Andami – SHMS")]),
          _c("p", [_vm._v("(PSM Based Safety and Health Management System)")]),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }